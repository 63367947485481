import { Canvas, CircleMarker, Util, latLng } from 'leaflet'

Canvas.include({
    _updateDirectionalMarker: function(layer) {
        if (!this._drawing || layer._empty()) {
            return
        }

        const p = layer._point
        const ctx = this._ctx

        this._layers[layer._leaflet_id] = layer

        const bearing = layer.options.bearing || 0
        const scale = layer.options.scale || 1

        const iconSize = 12
        const pointer = new Path2D(
            'M 16.315 17.711 C 16.538 17.84 16.817 17.823 17.022 17.668 C 17.225 17.526 17.301 17.261 17.206 17.032 L 11.776 3.639 C 11.682 3.41 11.457 3.264 11.21 3.272 C 10.965 3.255 10.734 3.386 10.623 3.604 L 5.256 17.004 C 5.161 17.233 5.238 17.497 5.44 17.64 C 5.641 17.794 5.915 17.811 6.133 17.683 L 11.217 14.649 L 16.315 17.711 Z'
        )

        ctx.save()
        ctx.beginPath()

        ctx.translate(p.x - iconSize, p.y - iconSize)

        ctx.translate(iconSize, iconSize)
        ctx.rotate((bearing * Math.PI) / 180)
        ctx.scale(scale, scale)
        ctx.translate(-iconSize, -iconSize)

        pointer.closePath()
        this._fillStroke(ctx, layer)
        ctx.fill(pointer)
        ctx.closePath()

        ctx.restore()
    },
})

export const DirectionalMarker = CircleMarker.extend({
    options: {
        bearing: 0,
        scale: 1,
    },
    _size: 24,
    initialize(latlng, options) {
        Util.setOptions(this, options)
        this._latlng = latLng(latlng)
        this.options.radius = (this._size * this.options.scale) / 2
        this._radius = this.options.radius
    },
    _updatePath() {
        this._renderer._updateDirectionalMarker(this)
    },
})
