<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import { debounce } from 'lodash'
import { findRealParent } from 'vue2-leaflet'
import { LatLngBounds, featureGroup, icon, marker, polyline } from 'leaflet'
import geometryutil from 'leaflet-geometryutil'
import moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'

import { DirectionalMarker } from '../leaflet/DirectionalMarker'
import { NonOverlappingFeatureGroup } from '../leaflet/NonOverlappingFeatureGroup'

momentDurationFormatSetup(moment)

const FLAG_ICON = require('@/assets/icons/flag.svg')
const PIN_ICON = require('@/assets/icons/pin.svg')
const HIGHLIGHTED_TRACE_COLOR = '#ef2655'
const HIGHLIGHTED_MARKER_COLOR = '#f89db2'

export default {
    name: 'TripHistoryLayer',
    props: {
        mapZoom: {
            type: Number,
            default: null,
        },
    },
    data() {
        return {
            destinationMarkers: new Map(),
            directionMarkers: new Map(),
            directionMarkersLayerCopy: null,
            mapInstance: null,
            tripHistoryLayer: null,
            tripPaths: new Map(),
        }
    },
    computed: {
        ...mapState('tracker', ['assetHistoryLocationId']),
        ...mapState('trip', ['isTripDirectionVisible', 'trips']),
        ...mapGetters('map', ['activeLayer']),
        ...mapGetters('trip', ['selectedTrip']),
        destinationMarkersLayer() {
            return featureGroup(
                this.selectedTrip
                    ? this.destinationMarkers.get(this.selectedTrip.id)
                    : [...this.destinationMarkers.values()].flat()
            )
        },
        directionMarkersLayer() {
            return new NonOverlappingFeatureGroup(
                this.selectedTrip
                    ? this.directionMarkers.get(this.selectedTrip.id)
                    : [...this.directionMarkers.values()].flat()
            )
        },
        pathsLayer() {
            return this.selectedTrip
                ? this.tripPaths
                      .get(this.selectedTrip.id)
                      .setStyle({ color: HIGHLIGHTED_TRACE_COLOR })
                : featureGroup([...this.tripPaths.values()])
        },
        traceBorderColor() {
            return this.activeLayer.traceBorderColor || '#228cdb'
        },
        traceColor() {
            return this.activeLayer.traceColor || '#a4c7f5'
        },
        tripCoordinates() {
            return this.trips.map(trip =>
                trip.measurements.reduce(
                    (acc, { id, timestamp, lat, lng }) =>
                        typeof lat === 'number' && typeof lng === 'number'
                            ? [...acc, { id, timestamp, lat, lng }]
                            : acc,
                    []
                )
            )
        },
    },
    watch: {
        activeLayer() {
            // When the map style changes, the trip path may need to change style as well
            this.refreshTtripHistoryLayer()
        },
        isTripDirectionVisible() {
            this.refreshDirectionMarkersLayer()
        },
        mapZoom() {
            this.refreshDirectionMarkersLayer()
        },
        selectedTrip(newTrip, oldTrip) {
            if (oldTrip) {
                this.tripPaths.get(oldTrip.id)?.setStyle({
                    color: this.traceColor,
                })
            }
            this.refreshTtripHistoryLayer()
            if (newTrip) {
                this.mapFlyToIfNotVisible(
                    this.tripCoordinates[this.trips.indexOf(newTrip)]
                )
            }
        },
        async trips() {
            ;[
                this.tripPaths,
                this.destinationMarkers,
                this.directionMarkers,
            ] = await Promise.all([
                this.createLayersConcurrently(this.createTripPathLine),
                this.createLayersConcurrently(this.createDestinationMarkers),
                this.createLayersConcurrently(this.createDirectionMarkers),
            ])
            this.$emit('alignMap', this.tripCoordinates.flat())
            this.refreshTtripHistoryLayer()
        },
    },
    mounted() {
        this.mapInstance = findRealParent(this.$parent, true).mapObject
        this.tripHistoryLayer = featureGroup().addTo(this.mapInstance)
        this.$emit('alignMap', this.tripCoordinates.flat())
        this.refreshTtripHistoryLayer()
    },
    beforeDestroy() {
        this.tripHistoryLayer?.removeFrom(this.mapInstance)
    },
    methods: {
        ...mapMutations('tracker', ['setAssetHistoryPointProposed']),
        ...mapMutations('trip', ['setSelectedTripId']),
        createLayersConcurrently(createLayer) {
            const layers = new Map()
            let i = -1
            return new Promise(resolve => {
                const create = () => {
                    if (++i < this.trips.length) {
                        setTimeout(create)
                        layers.set(
                            this.trips[i].id,
                            createLayer(this.trips[i], this.tripCoordinates[i])
                        )
                    } else {
                        resolve(layers)
                    }
                }
                create()
            })
        },
        createTripEndMarker(assetHistoryEntry) {
            const size = 30
            return marker(assetHistoryEntry, {
                axItem: assetHistoryEntry,
                icon: icon({
                    iconUrl: FLAG_ICON,
                    iconSize: [size, size],
                    iconAnchor: [5, size],
                    tooltipAnchor: [size / 2, -size / 2],
                }),
            })
        },
        createTripMarkers(trip, positions, createMarker) {
            let tripDuration = null
            let tripStart = null
            let tripEnd = null

            if (trip.last_measurement) {
                tripDuration = this.formatDuration(
                    new Date(trip.last_measurement.timestamp) -
                        new Date(trip.first_measurement.timestamp)
                )
                tripStart = this.formatTime(trip.first_measurement.timestamp)
                tripEnd = this.formatTime(trip.last_measurement.timestamp)
            }

            return positions.reduce((acc, position, i) => {
                const marker = createMarker(trip, i)?.on('click', () => {
                    this.setAssetHistoryPointProposed([
                        position.lat,
                        position.lng,
                    ])
                })
                if (!marker) {
                    return acc
                }

                let tooltipContent = this.formatTime(position.timestamp)
                if (trip.last_measurement) {
                    tooltipContent +=
                        `<hr>${this.$t('duration')}: ${tripDuration}` +
                        `<br>${this.$t('start')}: ${tripStart}` +
                        `<br>${this.$t('end')}: ${tripEnd}`
                    if (typeof trip.trip_distance === 'number') {
                        tooltipContent += `<br>${this.$t(
                            'distance'
                        )}: ${this.formatDistance(trip.trip_distance)}`
                    }
                }

                return [...acc, marker.bindTooltip(tooltipContent)]
            }, [])
        },
        createDirectionMarkers(trip, positions = []) {
            return this.createTripMarkers(trip, positions, (trip, i) =>
                trip.first_measurement.id !== positions[i].id &&
                trip.last_measurement.id !== positions[i].id
                    ? this.createTripMarker(positions[i], positions[i + 1])
                    : null
            )
        },
        createDestinationMarkers(trip, positions = []) {
            return this.createTripMarkers(trip, positions, (trip, i) =>
                trip.first_measurement.id === positions[i].id
                    ? this.createTripStartMarker(positions[i])
                    : trip.last_measurement.id === positions[i].id
                    ? this.createTripEndMarker(positions[i])
                    : null
            )
        },
        createTripMarker(position, positionNext) {
            return new DirectionalMarker(position, {
                axItem: position,
                bearing: positionNext
                    ? geometryutil.bearing(position, positionNext)
                    : 0,
                color:
                    this.assetHistoryLocationId === position.id
                        ? HIGHLIGHTED_MARKER_COLOR
                        : this.traceBorderColor,
                fillOpacity: 1,
                opacity: 1,
                scale: 1.6,
                weight: 0,
            })
        },
        createTripStartMarker(assetHistoryEntry) {
            const size = 30
            return marker(assetHistoryEntry, {
                axItem: assetHistoryEntry,
                icon: icon({
                    iconUrl: PIN_ICON,
                    iconSize: [size, size],
                    iconAnchor: [size / 2, size],
                    tooltipAnchor: [size / 2, -size / 2],
                }),
            })
        },
        createTripPathLine(trip, positions) {
            const pathLine = polyline(positions, {
                color: this.traceColor,
                interactive: true,
                weight: 7,
            })
                .on('click', () => {
                    if (this.selectedTrip) {
                        this.setSelectedTripId(null)
                    } else {
                        this.setSelectedTripId(trip.id)
                        this.$nextTick(() => {
                            this.mapInstance.once('click', () =>
                                this.setSelectedTripId(null)
                            )
                        })
                    }
                })
                .on('mouseover', () => {
                    if (!this.selectedTrip) {
                        this.tripPaths.get(trip.id).setStyle({
                            color: HIGHLIGHTED_TRACE_COLOR,
                        })
                    }
                })
                .on('mouseout', () => {
                    if (!this.selectedTrip) {
                        this.tripPaths.get(trip.id).setStyle({
                            color: this.traceColor,
                        })
                    }
                })

            if (trip.length > 1) {
                const firstPosition = trip[0]
                const lastPosition = trip[trip.length - 1]
                const tripDuration = this.formatDuration(
                    new Date(lastPosition.timestamp) -
                        new Date(firstPosition.timestamp)
                )
                const tripStart = this.formatTime(firstPosition.timestamp)
                const tripEnd = this.formatTime(lastPosition.timestamp)
                const tooltipContent =
                    `${this.$t('duration')}: ${tripDuration}<br>` +
                    `${this.$t('start')}: ${tripStart}<br>` +
                    `${this.$t('end')}: ${tripEnd}`
                pathLine.bindTooltip(tooltipContent, {
                    sticky: true,
                })
            }

            return pathLine
        },
        formatDistance(distance = 0) {
            return distance >= 1000
                ? `${(distance / 1000).toFixed(2)}km`
                : `${distance}m`
        },
        formatDuration(duration = 0) {
            return moment.duration(duration).format('h[h] mm[m] ss[s]')
        },
        formatTime(timestamp) {
            return moment(timestamp).format('DD.MM.YYYY HH:mm:ss')
        },
        mapFlyToIfNotVisible(positions) {
            const viewBounds = this.mapInstance.getBounds()
            const tripBounds = new LatLngBounds(positions)
            if (!viewBounds.contains(tripBounds)) {
                this.mapInstance.flyToBounds(tripBounds)
            }
        },
        refreshDirectionMarkersLayer: debounce(function() {
            this.directionMarkersLayerCopy?.removeFrom(this.tripHistoryLayer)
            this.directionMarkersLayerCopy = null
            if (this.isTripDirectionVisible && this.trips?.length) {
                this.directionMarkersLayerCopy = this.directionMarkersLayer
                this.tripHistoryLayer.addLayer(this.directionMarkersLayerCopy)
            }
        }, 100),
        refreshTtripHistoryLayer() {
            this.tripHistoryLayer.clearLayers()
            if (this.trips?.length) {
                setTimeout(() => {
                    this.tripHistoryLayer.addLayer(this.pathsLayer)
                    setTimeout(() => {
                        this.tripHistoryLayer.addLayer(
                            this.destinationMarkersLayer
                        )
                        this.refreshDirectionMarkersLayer()
                    })
                })
            }
        },
    },
    render(h) {
        return h() // avoid warning message because we don't have a template
    },
}
</script>

<i18n>
{
    "en": {
        "distance": "Distance",
        "duration": "Duration",
        "end": "End",
        "start": "Start"
    },
    "de": {
        "distance": "Distanz",
        "duration": "Dauer",
        "end": "Ende",
        "start": "Start"
    },
    "fr": {
        "distance": "Distance",
        "duration": "Durée",
        "end": "Fin",
        "start": "Début"
    },
    "it": {
        "distance": "Distanza",
        "duration": "Durata",
        "end": "Fine",
        "start": "Inizio"
    }
}
</i18n>
