import { FeatureGroup } from 'leaflet'
import { shuffle } from 'lodash'
import rbush from 'rbush'

export const NonOverlappingFeatureGroup = FeatureGroup.extend({
    options: {
        distanceFactor: 1.5,
    },
    onAdd(map) {
        const tree = new rbush()
        const visibleLayers = []
        for (const layer of shuffle(Object.values(this._layers))) {
            const { x, y } = map.latLngToLayerPoint(layer._latlng)
            const radius = layer.options.radius * this.options.distanceFactor
            const searchBox = {
                minX: x - radius,
                minY: y - radius,
                maxX: x + radius,
                maxY: y + radius,
            }
            if (!tree.search(searchBox).length) {
                visibleLayers.push(layer)
                tree.insert({
                    minX: x,
                    minY: y,
                    maxX: x,
                    maxY: y,
                    layer,
                })
            }
        }
        visibleLayers.forEach(layer => {
            map.addLayer(layer)
        })
        return this
    },
})
